@import "styles/definition/text.scss";
@import "styles/definition/variables.scss";

.energy-home {
  margin: 0 auto;
  padding: 20px;

  @media screen and (max-width: 768px) {
    padding: 16px;
  }

  &.energy-home--result {
    padding: 0;
    @media screen and (max-width: 768px) {
      margin: 0;
    }
  }

  .energy-home__votre {
    display: flex;
    align-items: center;
    justify-content: space-between;
    row-gap: 16px;
    flex-wrap: wrap;
    margin: auto;
    margin-bottom: 16px;
    max-width: 1420px;

    @media screen and (max-width: 992px) {
      flex-direction: column;
    }

    .energy-home__votre-item-link {
      flex-basis: calc(100% / 3 - 10px);
      @media screen and (max-width: 992px) {
        max-width: 320px;
      }
    }
    .energy-home__votre-item {
      background-size: cover;
      position: relative;
      width: 100%;
      height: 330px;
      padding: 16px;
      overflow: hidden;
      border-radius: 6px;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 8px 0px,
        rgba(0, 0, 0, 0.06) 0px 2px 4px 0px, rgba(0, 0, 0, 0.04) 0px 1px 2px 0px;
      @media screen and (max-width: 1280px) {
        height: 360px;
      }
      @media screen and (max-width: 1024px) {
        height: 420px;
      }
      @media screen and (max-width: 576px) {
        width: 100%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        transition: transform 0.3s ease-in, filter 0.3s ease-in;
        user-select: none;
        object-fit: cover;
      }
      &:hover {
        img {
          transform: scale(1.08);
          // filter: brightness(110%);
        }
      }

      .energy-home__votre-item__title {
        color: #fff;
        font-weight: 700;
        font-size: 26px;
        margin-bottom: 20px;
        position: relative;
        z-index: 10;
        text-transform: uppercase;
        @media screen and (max-width: 768px) {
          font-size: 24px;
        }
      }
      .energy-home__votre-item__desc {
        color: #fff;
        font-weight: 400;
        font-size: 18px;
        position: relative;
        z-index: 10;
        @media screen and (max-width: 768px) {
          font-size: 20px;
        }
      }
    }
  }

  .energy-home__banner-2 {
    max-width: 1420px;
    margin: auto;
    margin-top: 60px;
    margin-bottom: 40px;
  }

  .energy-home__section {
    max-width: 100%;
    margin: auto;
    background-color: #fff;
    padding: 16px;
    max-height: calc(100% - 200px);
    overflow: auto;
    @media screen and (max-width: 1280px) {
      max-width: 100%;
    }
    @media screen and (max-width: 576px) {
      max-height: calc(100% - 278px);
    }

    .energy-home__title {
      font-size: 32px;
      font-weight: 600;
      text-align: center;
      padding: 4px;
      border: 2px solid #0000001a;
      @media screen and (max-width: 768px) {
        font-size: 20px;
      }
    }
    h5 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 8px;
    }
    .energy-home__col {
      &:first-child {
        .energy-home__box {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          @media screen and (max-width: 576px) {
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
          }
        }
      }
      &:last-child {
        .energy-home__box {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          @media screen and (max-width: 576px) {
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
            border-left: 2px solid #0000001a !important;
          }
        }
      }
      .energy-home__box {
        border: 2px solid #0000001a;
        padding: 12px;
        border-radius: 12px;

        .project-item {
          cursor: pointer;
          img {
            border-radius: 10px 10px 0 0;
            width: 100%;
          }
          .project-item__info {
            border-radius: 0 0 10px 10px;
            border: 1px solid #000000;
            border-top: 0;
            padding: 8px 4px;
            padding-bottom: 16px;
            text-align: center;
          }
        }

        .list {
          li {
            position: relative;
            &::marker {
              color: transparent;
            }
            &::before {
              content: "";
              display: block;
              width: 12px;
              height: 12px;
              position: absolute;
              left: -16px;
              top: 5px;
              border-radius: 50%;
            }
          }
          .green {
            &::before {
              background-color: #89ea29;
            }
          }
          .yellow {
            &::before {
              background-color: #f2da04;
            }
          }
        }
      }
    }
  }

  .loading-center {
    background-color: #fff;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .energy-home__result {
    width: 85%;
    margin: auto;
    max-width: 1600px;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
    .ant-card-body {
      padding: 28px;
      @media screen and (max-width: 768px) {
        padding: 16px;
      }
    }

    .energy-home__result-auth {
      position: relative;
      .overlay {
        position: absolute;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.5); /* Semi-transparent background */
        backdrop-filter: blur(3px); /* Blur effect */
        .overlay__content {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          margin-top: 80px;
          flex-wrap: wrap;
          row-gap: 40px;
          .offre-item {
            background-color: #c8e4f0;
            max-width: 300px;
            padding: 20px 24px 28px;
            border-radius: 16px;
            .title {
              font-weight: 600;
              font-size: 32px;
              margin-bottom: 20px;
            }
            .description {
              font-size: 16px;
              margin-bottom: 32px;
            }
          }
        }
      }
    }

    .energy-home__project-card {
      padding: 24px 0 24px;

      @media screen and (max-width: 992px) {
        padding: 24px 12px;
      }
      @media screen and (max-width: 576px) {
        padding: 24px 0px;
      }

      .ant-card-head {
        border-bottom: 0;
        padding: 0 20px;
      }

      .energy-home__project {
        .energy-home__project-info {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          margin-top: 12px;
          gap: 16px;
          @media screen and (max-width: 1400px) {
            flex-wrap: wrap;
          }
          .ant-tabs {
            width: 800px;
            flex-shrink: 0;
            @media screen and (max-width: 1400px) {
              width: 100%;
            }

            .ant-tabs-nav-wrap {
              background-color: #f4f4f4;
              border-radius: 100px;
              padding: 4px;
              .ant-tabs-nav-list {
                width: 100%;
                .ant-tabs-tab {
                  padding: 3px;
                  margin: 0;
                  flex: 1;
                  justify-content: center;
                  border-radius: 100px;
                  @extend .text-regular;

                  .ant-tabs-tab-btn {
                    color: $black;
                  }

                  &.ant-tabs-tab-active {
                    background-color: #dcdcdc;
                    @extend .text-regular-bold;
                  }
                }
              }
            }
            .ant-tabs-ink-bar {
              display: none;
            }
            .ant-tabs-nav {
              &::before {
                display: none;
              }
            }
          }

          .info-du-bien {
            border-radius: 40px;
            padding: 16px 40px;
            padding-right: 24px;
            background-color: #f4f4f4;

            .info-du-bien__inner {
              display: flex;
              column-gap: 12px;
              row-gap: 8px;
              @media screen and (max-width: 1200px) {
                flex-wrap: wrap;
              }
            }

            ul {
              display: flex;
              flex-direction: column;
              row-gap: 8px;
              flex-shrink: 0;
              max-width: 400px;
              .text-truncate {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2; /* number of lines to show */
                line-clamp: 2;
                -webkit-box-orient: vertical;
              }

              &:first-child {
                flex-basis: 46%;
              }
              &:last-child {
                flex-basis: 54%;
              }
              @media screen and (max-width: 768px) {
                flex-basis: 100% !important;
              }
            }
            @media screen and (max-width: 600px) {
              padding: 16px 24px;
            }
          }
        }
      }

      .manage-infos {
        border-radius: 40px;
        padding: 16px 40px;
        background-color: #f4f4f4;
        display: flex;
        flex-direction: column;
        row-gap: 4px;
        max-width: 680px;
      }
    }

    .energy-home__votre {
      .votre-item {
        border: 1px solid #000000;
        padding: 5px 8px;
        border-radius: 10px;
        opacity: 0.3;
        pointer-events: none;
        &.active {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }

    .energy-home__resultats {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (max-width: 1200px) {
        align-items: center;
      }

      .ant-tabs {
        width: 80% !important;

        @media screen and (max-width: 600px) {
          width: 100% !important;
        }
      }

      .ant-badge {
        .ant-badge-count {
          width: 100px;
          height: 48px;
          border-radius: 28px;
          font-size: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .svg-image {
        text-align: left;
        width: 275px;

        .ant-skeleton {
          width: 100%;
        }
        .ant-skeleton-button {
          width: 100%;
          height: 332px;
        }
      }

      img {
        width: 275px;
        max-height: 332px;
      }
    }

    .energy-home__notifications {
      .notification-item {
        position: relative;
        &::marker {
          color: transparent;
        }
        &::before {
          content: "";
          display: block;
          width: 12px;
          height: 12px;
          position: absolute;
          left: -20px;
          top: 6px;
          border-radius: 50%;
        }
        &.info {
          &::before {
            background-color: #89ea29;
          }
        }
        &.warning {
          &::before {
            background-color: #f2da04;
          }
        }
        &.error {
          &::before {
            background-color: #ff4d4f;
          }
        }
      }
    }

    .energy-home__project-info__indice {
      background-color: #ededed;
      border-radius: 30px;
      padding: 24px;
      min-width: 283px;
      position: relative;
      z-index: 100;
      display: flex;
      align-items: flex-start;
      justify-content: space-evenly;
      flex-wrap: wrap;
      row-gap: 20px;
      .dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 4px;
      }
      .dot-large {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        border: 1px solid;
        display: flex;
        align-items: center;
        justify-content: center;
        &.dot-qualite {
          background-color: #94c795;
          border-color: #7abd7a;
        }
        &.dot-coherence {
          background-color: #f9c895;
          border-color: #fcbf7f;
        }
        &.dot-issue {
          background-color: #fef3f2;
          color: #bb3e34;
          border-color: #bb3e34;
          font-weight: 600;
        }
      }

      .fiabilite-number {
        font-size: 24px;
        font-weight: 600;
      }

      .anticon-info-circle {
        font-size: 16px;
        cursor: pointer;
      }

      .fiabilite-container {
        margin: 50px auto 20px;
        .gauge {
          width: 100%;
          height: 30px;
          border-radius: 15px;
          background: linear-gradient(to right, red, yellow, green);
          position: relative;
        }
        .pointer {
          position: absolute;
          top: -32px;
          width: 2px;
          height: 28px;
        }
        .labels {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          font-size: 14px;
        }
      }
      @media screen and (max-width: 1152px) {
        width: 100%;
      }
    }

    .energy-home__distribution {
      .bar-chart {
        margin: auto;
        height: 240px;
        margin-top: 30px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 50px;

        .bar {
          display: inline-block;
          width: 60px;
          margin: 0 5px;
          border-radius: 10px;
          position: relative;
          // fill: lightgray;
        }

        // .label {
        //   font-size: 14px;
        //   text-anchor: middle;
        //   font-weight: bold;
        // }

        .bar-label {
          position: absolute;
          width: 100%;
          top: -22px;
          font-weight: bold;
          text-align: center;
          @media screen and (max-width: 768px) {
            font-size: 10px;
          }
        }

        .percentage {
          font-size: 14px;
          text-anchor: middle;
          font-weight: 600;
        }

        .box {
          stroke: gray;
          stroke-width: 1px;
        }

        .arrow {
          fill: green;
          position: relative;
          top: 2px;
        }

        .indicator {
          position: absolute;
          bottom: -25px;
          width: 100%;
          text-align: center;
          font-size: 14px;
          font-weight: 700;
        }

        .indicator-triangle {
          position: absolute;
          bottom: -45px;
          width: 100%;
          .triangle {
            margin: 0 auto;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 16px solid black;
            position: relative;
            &::after {
              content: "";
              width: 0;
              height: 0;
              border-left: 7px solid transparent;
              border-right: 7px solid transparent;
              border-bottom: 10px solid white;
              position: absolute;
              top: 4px;
              left: -7px;
            }
          }
        }
      }
    }

    .energy-home__maps {
      .ant-tabs {
        width: 100%;
        .ant-tabs-nav-wrap {
          background-color: #f4f4f4;
          border-radius: 100px;
          padding: 4px;
          .ant-tabs-nav-list {
            width: 100%;
            .ant-tabs-tab {
              padding: 3px;
              margin: 0;
              flex: 1;
              justify-content: center;
              border-radius: 100px;
              @extend .text-regular;

              .ant-tabs-tab-btn {
                color: $black;
              }

              &.ant-tabs-tab-active {
                background-color: #dcdcdc;
                @extend .text-regular-bold;
              }
            }
          }
        }
        .ant-tabs-ink-bar {
          display: none;
        }
        .ant-tabs-nav {
          margin-bottom: 0;
          &::before {
            display: none;
          }
        }
      }

      .map-wrapper {
        margin-top: 16px;
        width: 100%;
        height: 480px;
        .leaflet-container {
          height: 100%;
        }
        .label-container--selected {
          outline: 2px solid #000;
        }
      }

      .autocomplete-item {
        border: 1px solid #dedede;
        background: #ffffffe5;
        padding: 28px 20px;
        border-radius: 30px;
        max-width: 317px;

        .autocomplete-item__title {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: 12px;
          font-weight: 600;
          font-size: 16px;
        }
      }
      .ant-skeleton {
        width: 100% !important;
        height: 176px;
        .ant-skeleton-image {
          height: 100%;
        }
      }
      .slick-slider .slick-slide {
        padding: 0 12px;
      }
      .slick-arrow {
        svg {
          width: 100%;
          height: 100%;
          path {
            fill: #000;
          }
        }
      }
    }
  }
}

.energy-home__banner {
  background-image: url("/assets/images/cover.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  padding: 70px 28px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  @media screen and (max-width: 1920px) {
    background-size: 100% 100%;
  }

  @media screen and (max-width: 768px) {
    padding: 60px 12px 48px;
  }
  .energy-home__title {
    font-size: 54px;
    color: #f4f4f4;
    line-height: normal;
    margin-bottom: 20px;
    font-weight: 600;
    @media screen and (max-width: 1280px) {
      font-size: 48px;
    }
    @media screen and (max-width: 768px) {
      font-size: 32px;
    }
  }

  .energy-home__desc {
    font-size: 20px;
    color: #f4f4f4;
    line-height: normal;
    margin-bottom: 45px;
    max-width: 90vw;
    font-weight: 400;
    white-space: pre-wrap;
    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }

  .jump-to-bottom {
    color: #f4f4f4;
    line-height: 15.73px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 8px;
  }

  #upload-form {
    max-width: 100%;
    margin: auto;
    padding: 16px;
    text-align: center;

    @media screen and (max-width: 1440px) {
      width: 60%;
    }
    @media screen and (max-width: 1280px) {
      width: 70%;
    }
    @media screen and (max-width: 810px) {
      width: 100%;
    }

    .ant-upload-wrapper {
      .ant-upload-drag {
        background-color: rgba(255, 255, 255, 0.2);
      }
      .ant-upload-text {
        color: #fff;
        font-size: 20px;
        @media screen and (max-width: 1280px) {
          font-size: 16px;
        }
      }
      .ant-upload-drag-icon {
        .anticon-spin {
          font-size: 64px;
          @media screen and (max-width: 1280px) {
            font-size: 56px;
          }
        }
        svg {
          width: 64px;
          height: 64px;
          fill: #fff;
          @media screen and (max-width: 1280px) {
            width: 56px;
            height: 56px;
          }
        }
      }
    }

    .ant-btn-primary {
      min-width: 180px;
      min-height: 50px;
    }
    .ant-upload {
      padding: 24px 16px;
    }

    .ant-upload-list-item {
      margin-top: 12px;
      justify-content: center;
      text-align: center;

      .ant-upload-icon svg {
        fill: #fff;
      }
      .ant-upload-list-item-name {
        color: #fff;
        font-size: 18px;
        max-width: max-content;
      }

      .ant-upload-list-item-actions {
        .ant-btn {
          opacity: 1;
        }
        .anticon-delete {
          margin-left: 16px;
          svg {
            fill: #fff;
          }
        }
      }
    }
  }

  .ademe-box {
    max-width: 100%;
    margin: auto;
    padding: 24px 16px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 12px;
    .ant-select {
      min-height: 37px;
    }

    .ant-select-selection-search-input {
      padding-right: 32px !important;
    }
  }

  .ant-tabs {
    max-width: 991px;
    width: 100%;
    @media screen and (max-width: 1152px) {
      max-width: 100%;
    }

    .ant-tabs-nav-wrap {
      background-color: #ffffff48;
      border-radius: 100px;
      padding: 4px;
      border: 1px solid $black;
      @media screen and (max-width: 768px) {
        padding: 8px;
      }
      .ant-tabs-nav-list {
        width: 100%;
        min-height: 44px;
        .ant-tabs-tab {
          padding: 3px;
          margin: 0;
          flex: 1;
          justify-content: center;
          border-radius: 100px;
          @extend .text-regular;

          .ant-tabs-tab-btn {
            color: $white;
            @media screen and (max-width: 768px) {
              font-size: 12px;
            }
          }

          &.ant-tabs-tab-active {
            background-color: #dcdcdc;
            @extend .text-regular-bold;
            .ant-tabs-tab-btn {
              color: $black;
            }
          }
        }
      }
    }
    .ant-tabs-ink-bar {
      display: none;
    }
    .ant-tabs-nav {
      &::before {
        display: none;
      }
    }
  }
}

.energy-home__search {
  display: flex;
  align-items: flex-end;
  gap: 8px;
  label {
    font-size: 14px;
    color: $white;
  }
  #search-banner {
    // background: #fefefe80;
    margin-top: 4px;
    padding: 7px;
  }
  .ant-btn-primary {
    height: 37.3333px;
  }
}

.drawer-menu {
  .ant-drawer-body {
    padding: 12px 0 0;
  }
  .ant-menu {
    border-inline-end: none !important;
  }
}

.modal-result-autocomplete {
  width: 1080px !important;
  max-width: 70%;

  @media screen and (max-width: 992px) {
    max-width: 95%;
  }

  &.modal-result-autocomplete--empty {
    max-width: 100%;
  }

  .autocomplete-box {
    padding: 16px 0;
    .autocomplete-item {
      border: 1px solid #dedede;
      box-shadow: 0px 4px 6px -2px #00000008, 0px 12px 16px -4px #00000014;
      background: #ffffffe5;
      padding: 28px 20px;
      border-radius: 30px;
      flex: 1;
      min-width: 300px;
      max-width: 300px;
      @media screen and (max-width: 700px) {
        max-width: 100%;
      }
      .autocomplete-item__title {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 12px;
        font-weight: 600;
        font-size: 16px;
      }
    }

    .ant-skeleton {
      width: 217px !important;
      height: 176px;
      .ant-skeleton-image {
        height: 100%;
        width: 100%;
      }
    }
  }

  .map-wrapper {
    width: 100%;
    height: 400px;
    .leaflet-container {
      height: 100%;
    }

    .label-container--selected {
      outline: 2px solid #000;
    }
  }
}

.modal-result-autocomplete-wrap {
  z-index: 99999 !important;
}
