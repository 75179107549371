@import "styles/definition/text.scss";
@import "styles/definition/variables.scss";

.energy-login {
  margin: 0 auto;
  padding: 20px;
  padding-top: 48px;
  background-color: white;
  min-height: calc(100vh - 64px);

  .energy-login__form {
    max-width: 600px;
    margin: auto;
    h1 {
      letter-spacing: -0.025em;
      margin-bottom: 32px;
      font-size: 48px;
      line-height: 56px;
      font-weight: 900;
      text-align: center;
    }

    .btn-connect {
      background-color: rgb(252, 92, 99);
      border-radius: 8px;
      height: 64px;
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      color: #fff;
      border-color: rgb(252, 80, 87) !important;
      width: 100%;
      &:hover {
        background-color: rgb(252, 80, 87);
        color: #fff;
      }
    }

    #login-form {
      max-width: 392px;
      margin: auto;
      input {
        background-color: rgb(245, 245, 245);
        width: 100%;
        height: 64px;
      }
    }
  }
}
